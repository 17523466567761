/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import { make } from 'vuex-pathify';

const meta = {
  namespaced: true,
  state: {
    title: process.env.VUE_APP_SITE_TITLE,
    description: process.env.VUE_APP_SITE_TITLE,
    // url: process.env.VUE_APP_SITE_URL,
    // canonical: process.env.VUE_APP_SITE_URL,
    head: {},
  },
  mutations: {},
  actions: {},
};

const mutations = {
  updateMeta: (state, payload) => {
    // console.log('updateMeta', payload);

    if (typeof payload.title === 'undefined' || payload.title === '') {
      state.title = process.env.VUE_APP_SITE_TITLE;
    } else {
      state.title = `${payload.title}`;
    }

    if (typeof payload.description === 'undefined' || payload.description === '') {
      state.description = process.env.VUE_APP_SITE_DESCRIPTION;
    } else {
      state.description = `${payload.description}`;
    }

    state.head = {};
  },
};

const actions = {
  updateMeta({ commit }, payload) {
    commit('updateMeta', payload);
  },
};

meta.mutations = Object.assign(make.mutations(meta.state), mutations);
meta.actions = Object.assign(meta.actions, actions);

export default meta;
