import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

// import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

import * as getters from './getters';
import { state, mutations } from './mutations';

import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    pathify.plugin,
    createPersistedState({
      paths: [
        // 'lang',
      ],
    }),
  ],

  state,
  mutations,
  getters,

  modules: {
    ...modules,
  },
});

export default store;
