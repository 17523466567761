import Vue from 'vue';

// Require in a base component context
const requireComponent = require.context('@/components/utility', false, /[\w-]+.vue$/);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');

  Vue.component(componentName, componentConfig.default || componentConfig);
});
