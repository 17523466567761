import { make } from 'vuex-pathify';

const general = {
  namespaced: true,
  state: {
    homePageDesktop: '/',
    homePageMobile: '/',
    scrollTop: 0,
    loading: false,
    pageOptions: {},

    ongoingSeason: '2021-2022',
    ongoingSeasonEndYear: '2022',
    currentSeason: '',
    currentSeasonId: 0,
    currentCategoryId: 0,
    currentCategoryName: '',
    currentRegionId: 0,
    currentRegionName: '',
    currentTeamId: 0,
    currentTeamName: '',
    currentTournament: {},
    currentTournamentId: 0,
    currentTournamentName: '',
    currentDivisionId: 0,
    currentDivisionName: '',
    currentLeagueId: 0,
    currentLeagueName: '',
    currentGameId: 0,
    currentUserId: 0,
    currentSupervisorId: 0,
    currentSupervisorUsername: '',
    menu: {
      seasons: {},
      regions: {},
      categories: {},
    },
  },
  mutations: {},
  actions: {},
};

general.mutations = make.mutations(general.state);

export default general;
