import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
  },
  {
    path: '/claudia-with-love-21hf78',
    name: 'claudia',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Claudia.vue'),
  },
  {
    path: '/maman-avec-amour-95fk21',
    name: 'maman',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Maman.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
