<template>
  <div id="app">
    <router-view />

    <Snow
      v-if="show.snow"
      :active="true"
      z-index="2"
      :wind="1"
      :swing="3"
      speed="m"
      color="#ffffff"
    />

    <AudioPlayer v-if="show.audioPlayer" />
  </div>
</template>

<script>
export default {
  components: {
    Snow: () => import('vue-niege'),
    AudioPlayer: () => import(/* webpackChunkName: "page--common" */ '@/components/AudioPlayer'),
  },

  data() {
    return {
      show: {
        snow: false,
        audioPlayer: true,
      },
    };
  },

  created() {
    if (!this.isDev()) {
      this.show.snow = true;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>

<style>
@import url(https://fonts.googleapis.com/css?family=Nobile:400italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script);

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  cursor: url(../public/images/xmas-tree.cur), auto !important;
  background: url(../public/images/ho-ho-ho.jpg) no-repeat center center fixed;
  background-size: cover;
  -webkit-transition: background-image 1.5s ease-in-out;
  transition: background-image 1.5s ease-in-out;
}

::-moz-selection {
  background: #a390e4;
}

::selection {
  background: #a390e4;
}

::-moz-selection {
  background: #a390e4;
}

.notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
