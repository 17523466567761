import Vue from 'vue';

import store from '../vuex/store';

/* Global Methods */
Vue.mixin({
  methods: {
    ucfirst: (str) => str.charAt(0).toUpperCase() + str.slice(1),

    metaTitle() {
      return store.state.meta.title;
    },

    isIos() {
      return process.env.CORDOVA_PLATFORM === 'ios';
    },

    isAndroid() {
      return process.env.CORDOVA_PLATFORM === 'android';
    },

    isApp() {
      return ['android', 'ios'].includes(process.env.CORDOVA_PLATFORM);
    },

    isWeb() {
      return !['android', 'ios'].includes(process.env.CORDOVA_PLATFORM);
    },

    isDev() {
      return process.env.NODE_ENV !== 'production';
    },

    isMobileUA() {
      return /Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);
    },

    isMobile() {
      return !this.isDesktop();
    },

    isDesktop() {
      return !this.isMobileUA() && !this.isApp();
    },

    log(...args) {
      if (this.isSuperAdmin()) {
        console.log(...args);
      }
    },

    ray(...args) {
      if (this.isSuperAdmin()) {
        this.$ray(...args);
      }
    },
  },
});
