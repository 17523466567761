import Vue from 'vue';

import App from '@/App.vue';

import router from '@/router';
import store from '@/vuex/store';

// import '@/auth';
import '@/registerServiceWorker';
import '@/mixins/filters';

import '@/global/components';
import '@/global/components-utility';
import '@/global/mixins';

import '@/assets/css/tailwind.css';

import '@/plugins/vue-audio';

window.Bus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
